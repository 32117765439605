.main_banner {
  width: 100%;
  height: 350px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px) {
    background-position: 85%;
  }
  @media (max-width: 480px) {
    height: 230px;
    background-position: 80%;
  }
  .banner_text {
    width: 80%;
    margin: 0 auto;
    max-width: 1200px;
    color: white;
    text-shadow: 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.5);
    h3 {
      font-size: 20px;
      color: white;
    }
    h2 {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 40px;
      color: white;
    }
    @media (max-width: 768px) {
      h3 {
        font-size: 6.5vw;
      }
      h2 {
        font-size: 4.5vw;
      }
    }

    .detail_button {
      padding: 8px 10px;
      font-size: 13px;
      color: white;
      background-color: #3c5997;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      text-shadow: none;
      @media (max-width: 768px) {
        font-size: 11px;
      }
    }
  }
}

.home-container {
  .contents_inner {
    width: 70%;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 50px;
    @media (max-width: 768px) {
      width: 90%;
      margin: 30px auto;
      gap: 30px;
    }
    .rankingList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}

.noticeZone {
  width: 100%;
  padding: 5px 10px 7px 10px;
  background-color: #f0f3f5;
  border-radius: 10px;
  .notice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h3 {
      font-size: 14px;
      font-weight: normal;
      display: inline-block;
      color: #4c4c4c;
      width: 50%;
    }
    p {
      font-size: 13px;
      text-align: right;
      width: 50%;
      display: inline-block;
      color: #4c4c4c;
    }
  }
}

.tabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
  .leftTabs,
  .rightTabs {
    width: 45%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .tabList {
      display: flex;
      justify-content: center;
      background-color: #f0f3f5;
      border-radius: 5px;
      overflow: hidden;
      button:not(:last-of-type) {
        border-right: 1px solid #ddd;
      }
      button {
        padding: 10px 5px;
        cursor: pointer;
        flex: 1;
        font-weight: bold;
        &.active {
          font-weight: bold;
          background-color: #05aced;
          color: white;
          border: none;
        }
      }
    }
  }
}

.postListBox {
  width: 100%;
  padding: 10px;

  .postList {
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 5px;
    .postBox {
      width: 100%;
      padding: 5px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      &:not(:nth-of-type(1)) {
        border-top: 1px solid #ddd;
      }
      h4 {
        font-size: 14px;
        font-weight: normal;
        color: #222;
        @media (max-width: 768px) {
        }
      }
      p {
        font-size: 12px;
        color: #222;
      }
    }
  }
}
