// Footer.jsx
.footer {
  height: 300px;
  background-color: rgb(222, 226, 229);
  padding: 20px;
  color: #5c667b;
  display: flex;
  flex-direction: column;
  gap: 30px 0;
  ul,
  li,
  ol {
    list-style: none;
  }
  @media (max-width: 1024px) {
    height: 300px;
    padding: 5% 8%;
  }
  @media (max-width: 767px) {
    height: 400px;
    padding: 10% 8%;
  }
  > div {
    width: 80%;
    margin: 0 auto;
    max-width: 1200px;
    @media (max-width: 1024px) {
      width: 100%;
    }
    h2 {
      font-weight: 600;
      font-size: 14px;
      margin: 30px 0 10px 0;
      @media (max-width: 1024px) {
        font-weight: 600;
        font-size: 18px;
        margin: 0 0 15px 0;
      }
    }
    ul.help_area,
    ul.company_area,
    .terms ul {
      display: flex;
      justify-content: flex-start;
      gap: 0 10px;
      @media (max-width: 767px) {
        flex-direction: column;
        gap: 7px;
        margin-top: 7px;
      }
      li {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 6px;
        span {
          font-weight: 500;
          margin-right: 5px;
        }
      }
    }

    .copyline {
      margin: 0;
      padding: 20px 0 10px 0;
      font-size: 11px;
      @media (max-width: 1024px) {
        padding: 15px 0 30px 0;
      }
    }
  }
}

// Header.jsx
header {
  width: 100%;
  background-color: white;
  ul,
  li,
  ol {
    list-style: none;
  }
  &.scrolling {
    top: -60px;
  }
  .header_inner {
    width: 80%;
    margin: 0 auto;
    max-width: 1200px;
    @media (max-width: 768px) {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 150;
      padding: 0 2.5%;
      background: white;
    }
  }
  .header_oneFake {
    display: none;
    width: 100%;
    height: 60px;
    @media (max-width: 768px) {
      display: block;
    }
    @media (max-width: 480px) {
      height: 50px;
    }
  }
  .header_one {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      height: 60px;
    }
    @media (max-width: 480px) {
      height: 50px;
    }
    h1 {
      font-size: 18px;
      line-height: 50px;
      font-weight: bold;
      img {
        height: 35px;
        width: auto;
      }
      a {
        display: inline-block;
        display: flex;
        align-items: center;
        color: #4c4c4c;
      }
    }
    ul.login {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;
      margin-left: auto;
      li {
        font-size: 14px;
        cursor: pointer;
        border-radius: 5px;
        height: 40px;
        line-height: 40px;
        font-weight: bold;
        font-weight: 500;
        padding: 0 15px;
        @media (max-width: 768px) {
          font-size: 14px;
          padding: 0 3vw;
          height: 30px;
          line-height: 30px;
        }
        a {
          display: inline-block;
          line-height: inherit;
        }
        &:nth-of-type(1) {
          background-color: #f0f3f5;
          @media (max-width: 768px) {
            display: none;
          }
        }
        &:nth-of-type(2) {
          background-color: #3c5997;
          color: white;
          a {
            color: white;
          }
        }
      }
    }
  }

  nav {
    white-space: nowrap;
    width: 100%;
    position: relative;
    height: 27px;
    z-index: 1;
    ul.mainmenu {
      height: 27px;
      position: absolute;
      overflow: hidden;
      transition: height 0.5s ease-in-out;
      background-color: #ffffff;
      &:hover {
        cursor: pointer;
        height: 170px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border-bottom: 1px solid #ddd;
      }
    }
  }

  nav ul.mainmenu {
    display: flex;
    width: auto;
    gap: 15px;
    > li {
      cursor: pointer;
      font-weight: 500;
      font-size: 15px;
      position: relative;
      > a {
        padding: 0 10px 5px 10px;
        display: block;
        width: 100%;
        text-align: center;
      }
      &.selected {
        > a {
          color: #3c5997;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0px;
            width: 100%;
            border-bottom: 3px solid #3c5997;
          }
        }
      }
      &:hover {
        > a {
          color: #7388b6;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0px;
            width: 100%;
            border-bottom: 3px solid #7388b6;
          }
        }
      }
    }
    > li ul {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: 13px;
      padding: 5px 0 30px 0;
      li {
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s ease, color 0.3s ease;
        &:hover {
          background-color: #f0f3f5;
          color: #3c5997;
        }
        a {
          padding: 3px;
          display: block;
          text-align: center;
        }
      }
    }
  }
  .logout_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .modal_content {
      background: white;
      padding: 30px 10px;
      border-radius: 5px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      z-index: 3;
      @media (max-width: 768px) {
        width: 85%;
        padding: 25px 10px;
      }
      p {
        width: 100%;
        margin-bottom: 30px;
        @media (max-width: 768px) {
          margin-bottom: 15px;
        }
      }
      button {
        width: 30%;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        cursor: pointer;

        &:nth-of-type(1) {
          background-color: #7388b6;
          &:hover {
            background-color: #9ab1d6;
          }
        }
        &:nth-of-type(2) {
          background-color: #3c5997;
          &:hover {
            background-color: #2c4375;
          }
        }
      }
    }
  }
}
.hamburger-menu {
  display: none; /* 기본적으로 숨기기 */
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-right: 15px;
  .bar {
    width: 24px;
    height: 2.5px;
    background: #333;
    margin: 2.5px 0;
    border-radius: 2px;
  }
}
@media (max-width: 768px) {
  /* 테블릿 및 모바일 사이즈 */
  .hamburger-menu {
    display: flex; /* 햄버거 메뉴 아이콘 표시 */
  }
  nav {
    display: none; /* 기본 내비게이션 숨기기 */
  }
  .header_inner {
    flex-direction: column;
  }
}
// MobileNav.jsx
.MobileNavbar {
  display: none;
}

@media (max-width: 768px) {
  .MobileNavbar {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    background-color: #f8f9fa;
    padding: 10px 0;
    border-top: 1px solid #ddd;
  }

  .MobileNavbar.hidden {
    display: none;
  }

  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: #555;
    img {
      cursor: pointer;
    }
  }
}

// SidebarMenu.jsx
.sidebar-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 50vw;
  height: 100vh;
  background-color: white;

  display: flex;
  flex-direction: column;
  padding: 50px 25px 100px 25px;
  z-index: 100;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: scroll;
  ul,
  li,
  ol {
    list-style: none;
  }
  @media (max-width: 480px) {
    width: 100vw;
    height: 100vh;
  }
  &.open {
    transform: translateX(0);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  }
  .close-btn {
    position: absolute;
    top: 25px;
    right: 25px;
    align-self: flex-end;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  h1 {
    a {
      font-size: 24px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-weight: bold;
      img {
        height: 45px;
        padding-top: 5px;
      }
    }
  }
  p.loginText {
    font-size: 14px;
    color: rgb(157 164 174);
    margin: 10px 0;
    width: 100%;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
  ul.login {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    li {
      width: 100%;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      padding: 0 15px;
      @media (max-width: 480px) {
        font-size: 16px;
      }
      a {
        width: 100%;
        height: 100%;
        display: inline;
        font-weight: 500;
      }
      &:nth-of-type(1) {
        background-color: #f0f3f5;
      }
      &:nth-of-type(2) {
        background-color: #3c5997;
        color: white;
        a {
          color: white;
        }
      }
    }
  }
  ul.sideMenu {
    list-style: none;
    margin-top: 20px;
    > li {
      padding: 10% 0;
      @media (max-width: 480px) {
        padding: 5% 0;
      }
      > a {
        font-weight: bold;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid #ddd;
        > a {
          color: #222;
          font-size: 15px;
          @media (max-width: 480px) {
            font-size: 13px;
          }
        }
      }
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        gap: 5px;
        justify-content: space-between;
        li {
          width: 45%;

          a {
            color: #333;
            font-size: 18px;
            @media (max-width: 480px) {
              font-size: 16px;
            }
          }
        }
      }
    }
    a {
      text-decoration: none;
    }
    a:hover {
      color: #3c5997;
    }
  }
}

// SubMenu.jsx
.submenu {
  position: absolute;
  left: 0px;
  top: 27px;
  width: inherit;
  margin: 0 auto;

  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  overflow: hidden;
  animation: slideDown 0.5s ease-in-out;
  width: 99%;
  gap: 20px;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    font-size: 13px;
    width: auto;
    li {
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.3s ease, color 0.3s ease;
      background-color: gold;
      a {
        display: inline-block;
        font-weight: 500;
        padding: 0 10px 5px 10px;
      }
      &:hover {
        background-color: #f0f3f5;
        color: #3c5997;
      }
    }
  }
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 150px;
    opacity: 1;
  }
}
