// BoardManagement.jsx
.board-management {
  width: 75%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0;
  }
  .seletedDelete {
    background-color: #f44336;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
  }
  .seletedDelete:hover {
    background-color: #d32f2f;
    color: white;
  }
  /*------------------게시물테이블------------------*/
  table.board_table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    tr {
      cursor: pointer;
      &:hover {
        background-color: #f9f9f9;
      }
    }
    th,
    td {
      border: 1px solid #ddd;
    }

    th {
      background-color: #f4f4f4;
      font-size: 14px;
      padding: 10px 0;
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 5px;
      }
    }

    td {
      font-size: 14px;
      padding: 3px 0;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 2px;
      }
      select {
        width: 80%;
        padding: 3px 0;
        font-size: 14px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }

    button {
      margin: 5px;
      padding: 8px 16px;
      border: none;
      border-radius: 5px;
      background-color: #3c5997;
      color: white;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s;
      &:hover {
        background-color: #7388b6;
      }
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 5px 10px;
        white-space: nowrap;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .pagination button {
    margin: 0 5px;
    padding: 8px 12px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .pagination span {
    margin-left: 10px;
    font-size: 14px;
  }
  .pagination button.active {
    background-color: #007bff;
    color: white;
  }
}

// CategorySetting.jsx
.categorySettings {
  margin: 20px 0;
  border-radius: 8px;

  h4 {
    font-size: 16px;
    margin-bottom: 15px;
    color: #333;
  }

  .category-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    tr {
      text-align: center;
      font-size: 14px;
      background-color: #fff;
      line-height: 25px;
      /* 숨김 상태인 카테고리에 대한 스타일 */
      &.hidden-category {
        background-color: #f0f0f0;

        td,
        p {
          color: #888;
        }
      }
      th,
      td {
        border: 1px solid #ddd;
        font-size: 14px;
        select {
          font-size: 14px;
          padding: 3% 10%;
          display: inline;
        }
      }
      th {
        background-color: #f4f4f4;
        padding: 10px;
      }
      td {
        padding: 5px;
      }
    }
  }
  /*---------------------테이블 안 버튼 설정---------------------*/
  .category-table button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    &:nth-of-type(even) {
      margin-left: 5px;
    }
  }

  .category-table button.edit {
    background-color: #2196f3;
    color: white;
  }
  .category-table button.delete {
    background-color: #f44336;
    color: white;
  }
  .category-table button.delete:hover {
    background-color: #d32f2f;
  }
  .category-table button.edit:hover {
    background-color: #1976d2;
  }

  .category-table button.save {
    background-color: #4caf50;
    color: white;
  }

  .category-table button.save:hover {
    background-color: #45a049;
  }

  .category-table button.cancel {
    background-color: #f44336;
    color: white;
  }

  .category-table button.cancel:hover {
    background-color: #d32f2f;
  }

  /*---------------------카터고리 추가---------------------*/
  .add-category {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    input {
      padding: 8px;
      font-size: 14px;
      margin-right: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    button {
      padding: 5px 10px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      background-color: #4caf50;
      color: white;
      transition: background-color 0.3s;
      &:hover {
        background-color: #45a049;
      }
    }
  }
}

.premium-button-container {
  position: fixed;
  bottom: 100px;
  right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: column;
  @media (max-width: 768px) {
    bottom: 50px;
    right: 30px;
  }
}

.premium-button {
  background-color: #ff5722;
  color: white;
  border: none;
  width: 50px;
  height: 50px;
  font-size: 24px;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-110%, -50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
  transition: font-size 0.3s ease, transform 0.3s ease;
}
.premium-button-container:hover {
  .premium-button {
    background-color: #e64a19;
    transform: scale(1.1);
  }
  .tooltip {
    background-color: #444; /* 호버 시 배경색 변경 */
    font-size: 16px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* 호버 시 그림자 강화 */
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 반응형 디자인 */
@media (max-width: 600px) {
  .premium-button {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.categorySettings {
  margin: 20px 0;
  border-radius: 8px;

  h4 {
    font-size: 16px;
    margin-bottom: 15px;
    color: #333;
  }

  .category-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    tr {
      text-align: center;
      font-size: 14px;
      background-color: #fff;
      line-height: 25px;
      /* 숨김 상태인 카테고리에 대한 스타일 */
      &.hidden-category {
        background-color: #f0f0f0;

        td,
        p {
          color: #888;
        }
      }
      th,
      td {
        border: 1px solid #ddd;
        font-size: 14px;
      }
      th {
        background-color: #f4f4f4;
        padding: 10px;
      }
      td {
        padding: 5px;
      }
    }
  }
  /*---------------------테이블 안 버튼 설정---------------------*/
  .category-table button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    &:nth-of-type(even) {
      margin-left: 5px;
    }
  }

  .category-table button.edit {
    background-color: #2196f3;
    color: white;
  }
  .category-table button.delete {
    background-color: #f44336;
    color: white;
  }
  .category-table button.delete:hover {
    background-color: #d32f2f;
  }
  .category-table button.edit:hover {
    background-color: #1976d2;
  }

  .category-table button.save {
    background-color: #4caf50;
    color: white;
  }

  .category-table button.save:hover {
    background-color: #45a049;
  }

  .category-table button.cancel {
    background-color: #f44336;
    color: white;
  }

  .category-table button.cancel:hover {
    background-color: #d32f2f;
  }

  /*---------------------카테고리 추가---------------------*/
  .add-category {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    input {
      padding: 8px;
      font-size: 14px;
      margin-right: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    button {
      padding: 5px 10px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      background-color: #4caf50;
      color: white;
      transition: background-color 0.3s;
      &:hover {
        background-color: #45a049;
      }
    }
  }
}

.ranking-management {
  width: 75%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0;
  }
}

.searchBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 20px 0;
  background-color: #f7f7f7;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filterRow {
  display: flex;
  gap: 16px;
}

.filterField {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.filterLabel {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.filterInput,
.filterSelect {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.buttonGroup {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
  width: 100%;
}

.searchButton,
.resetButton {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3c5997;
  color: #fff;
  transition: background-color 0.3s ease;
}

.searchButton:hover,
.resetButton:hover {
  background-color: #2c4372;
}

.resetButton {
  background-color: #e74c3c;
}

.resetButton:hover {
  background-color: #c0392b;
}

@media (max-width: 768px) {
  .filterRow {
    flex-direction: column;
  }

  .searchBox {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    padding: 12px;
  }

  .filterRow {
    width: 49%;
    gap: 6px;
  }

  .filterLabel {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    color: #333;
  }

  .filterInput,
  .filterSelect {
    padding: 8px;
    font-size: 11px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
    width: 100%;
  }

  .searchButton,
  .resetButton {
    padding: 6px 12px;
    font-size: 12px;
  }
}

.statisticsManagement_container {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0 200px 0;
  @media (max-width: 768px) {
    height: calc(100vh - 350px);
  }
  .pbr {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .mbr {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0;
  }
  div.description {
    width: 100%;
    text-align: center;
    margin: 15% 0 8% 0;
    font-size: 16px;
    p {
      margin-bottom: 11px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  button.navyBox {
    display: block;
    width: 40%;
    margin: 0 auto;
    padding: 12px;
    background-color: #3c5997;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    a {
      color: white;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #7388b6;
    }
  }
}

.userManagement_container {
  width: 75%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0 100px 0;
  }

  .user_table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;

    @media (max-width: 1024px) {
      display: block;
      overflow-x: scroll;
      overflow-y: visible;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
    th,
    td {
      border: 1px solid #ddd;
    }

    th {
      background-color: #f4f4f4;
      font-size: 14px;
      padding: 10px 0;
      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 12px;
        padding: 5px;
        /*overflow-x: scroll; 원호가 지움*/
      }
    }

    td {
      font-size: 14px;
      padding: 3px 2px;
      text-align: center;
      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 12px;
        padding: 2px;
      }

      input {
        width: 60%;
        padding: 3px 0;
        font-size: 14px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      select {
        width: 100%;
        padding: 3px 0;
        font-size: 14px;
        margin: 0 5px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }

    button {
      margin: 5px;
      padding: 8px 16px;
      border: none;
      border-radius: 5px;
      background-color: #3c5997;
      color: white;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s;
      white-space: nowrap;
      &:hover {
        background-color: #7388b6;
      }
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 5px 10px;
        white-space: nowrap;
      }
    }
  }

  /*------------------------페이지------------------------ */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button {
      margin: 0 10px;
      padding: 8px 16px;
      border: none;
      border-radius: 5px;
      background-color: #3c5997;
      color: white;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s;
    }
    button:disabled {
      background-color: #c0c0c0;
      cursor: not-allowed;
    }
    button:hover:not(:disabled) {
      background-color: #7388b6;
    }
    span {
      align-self: center;
    }
  }
}
