.findusername-container {
  padding: 80px 0 150px 0;
  ul,
  li,
  ol {
    list-style: none;
  }
  .findusername-box {
    max-width: 350px;
    width: 70%;
    margin: auto;
    background-color: white;

    @media (max-width: 768px) {
      width: 85%;
      height: calc(100vh - 80px);
    }
  }
  h3 {
    font-size: 24px;
    margin: 50px 0;
    @media (max-width: 768px) {
      font-size: 20px;
      margin: 30px 0;
    }
  }
  p {
    font-size: 14px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 12px;
      white-space: nowrap;
      margin: 15px 0;
    }
  }
  .input-container {
    margin-bottom: 15px;
    text-align: left;

    &.security {
      margin-top: 15%;
    }

    label {
      display: block;
      color: rgb(156, 165, 173);
      font-size: 12px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    input {
      width: 95%;
      padding: 10px 0 10px 5px;
      border: none;
      border-bottom: 1px solid #d0d7de;
      font-size: 16px;
      color: rgb(156, 165, 173);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    input:focus {
      border-color: #222;
      outline: none;
      color: #222;
    }

    select {
      width: 95%;
      margin-top: 10px;
      padding: 10px 0;
      border: none;
      border-bottom: 1px solid #d0d7de;
      font-size: 16px;
      color: rgb(156, 165, 173);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    select:focus {
      border-color: #222;
      outline: none;
      color: #222;
    }
  }

  button.navyBox {
    width: 100%;
    padding: 12px;
    background-color: #3c5997;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    &:hover {
      background-color: #7388b6;
    }
  }

  .found-username {
    margin-top: 20px;
    font-size: 14px;
    color: #333;
    text-align: center;
    strong {
      font-weight: bold;
      color: #3c5997;
    }
  }
}

.forgotpassword-container {
  padding: 80px 0 150px 0;
  ul,
  li,
  ol {
    list-style: none;
  }
  .forgotpassword-box {
    max-width: 350px;
    width: 70%;
    margin: auto;
    background-color: white;

    @media (max-width: 768px) {
      width: 85%;
      height: calc(100vh - 80px);
    }
  }
  button.goBack {
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    width: 45px;
    height: 45px;
    text-align: left;
    position: relative;
    img {
      width: 30%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  h3 {
    font-size: 24px;
    margin: 50px 0;
    @media (max-width: 768px) {
      font-size: 20px;
      margin: 30px 0;
    }
  }
  p {
    font-size: 14px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 12px;
      white-space: nowrap;
      margin: 15px 0;
    }
  }
  .input-container {
    margin-bottom: 15px;
    text-align: left;
    &.security {
      margin-top: 15%;
    }
    ul {
      margin-left: 15px;
      margin-top: 5px;
      margin-bottom: 20px;
      li {
        list-style: disc;
        color: #555;
        font-size: 14px;
        line-height: 20px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
    label {
      display: block;
      color: rgb(156, 165, 173);
      font-size: 12px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    input {
      width: 95%;
      padding: 10px 0 10px 5px;
      border: none;
      border-bottom: 1px solid #d0d7de;
      font-size: 16px;
      color: rgb(156, 165, 173);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    input:focus {
      border-color: #222;
      outline: none;
      color: #222;
    }
    select {
      width: 95%;
      margin-top: 10px;
      padding: 10px 0;
      border: none;
      border-bottom: 1px solid #d0d7de;
      font-size: 16px;
      color: rgb(156, 165, 173);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    select:focus {
      border-color: #222;
      outline: none;
      color: #222;
    }
  }
  .error {
    margin-top: 5px;
    font-size: 11px;
    color: rgb(255, 80, 0);
  }
  button.navyBox {
    width: 100%;
    padding: 12px;
    background-color: #3c5997;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    &:hover {
      background-color: #7388b6;
    }
  }
}

.login-container {
  background-color: rgb(244, 244, 244);
  padding: 100px 0;
  @media (max-width: 768px) {
    padding: 0;
  }
}
.login-box {
  max-width: 350px;
  width: 70%;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 80px);
    border-radius: 0px;
    box-shadow: 0 0 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
h2 {
  img {
    width: 35px;
    height: 35px;
  }
  margin-bottom: 20px;
  @media (max-width: 768px) {
    img {
      width: 50px;
      height: 50px;
    }
  }
}
.input-container {
  margin-bottom: 15px;
  text-align: left;
  @media (max-width: 768px) {
    width: 85%;
    text-align: center;
  }
  label {
    display: block;
    color: rgb(156, 165, 173);
    font-size: 12px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  input {
    width: 95%;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #d0d7de;
    font-size: 16px;
    color: rgb(156, 165, 173);
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  input:focus {
    border-color: #222;
    outline: none;
    color: #222;
  }
}

.smallText {
  font-size: 12px;
  text-align: right;
  padding: 10px 0;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  a {
    color: rgb(132 141 148);
    font-weight: bold;
    padding: 5px 10px;
    display: block;
  }
}

.btnBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  > div,
  > button {
    width: 100% !important;
    height: 40px !important;
  }
  @media (max-width: 768px) {
    width: 80%;
    font-size: 16px;
  }
  .kakaoLogin {
    background-color: #fee500 !important;
    position: relative !important;
    img {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .naverIdLogin {
    background-color: #03c75a !important;
    position: relative !important;
    border-radius: 3px;
    img {
      width: auto;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  button.navyBox {
    background-color: #3c5997;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    font-weight: bold;
    a {
      color: white;
    }
    &:hover {
      background-color: #7388b6;
    }
  }
}

.resetpassword-container {
  padding: 80px 0 150px 0;
  ul,
  li,
  ol {
    list-style: none;
  }
  .resetpassword-box {
    max-width: 350px;
    width: 70%;
    margin: auto;
    background-color: white;

    @media (max-width: 768px) {
      width: 85%;
      height: calc(100vh - 80px);
    }
  }
  button.goBack {
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    width: 45px;
    height: 45px;
    text-align: left;
  }
  h3 {
    font-size: 24px;
    margin: 50px 0;
    @media (max-width: 768px) {
      font-size: 20px;
      margin: 30px 0;
    }
  }
  p {
    font-size: 14px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 12px;
      white-space: nowrap;
      margin: 15px 0;
    }
  }
  .input-container {
    margin-bottom: 15px;
    text-align: left;
    &.security {
      margin-top: 15%;
    }
    ul {
      margin-left: 15px;
      margin-top: 5px;
      margin-bottom: 20px;
      li {
        list-style: disc;
        color: #555;
        font-size: 14px;
        line-height: 20px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
    label {
      display: block;
      color: rgb(156, 165, 173);
      font-size: 12px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    input {
      width: 95%;
      padding: 10px 0 10px 5px;
      border: none;
      border-bottom: 1px solid #d0d7de;
      font-size: 16px;
      color: rgb(156, 165, 173);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    input:focus {
      border-color: #222;
      outline: none;
      color: #222;
    }
    select {
      width: 95%;
      margin-top: 10px;
      padding: 10px 0;
      border: none;
      border-bottom: 1px solid #d0d7de;
      font-size: 16px;
      color: rgb(156, 165, 173);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    select:focus {
      border-color: #222;
      outline: none;
      color: #222;
    }
  }
  .error {
    margin-top: 5px;
    font-size: 11px;
    color: rgb(255, 80, 0);
  }
  button.navyBox {
    width: 100%;
    padding: 12px;
    background-color: #3c5997;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    a {
      color: white;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #7388b6;
    }
  }
}

.modalChage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
  }
  .input-container {
    margin-bottom: 15px;
    text-align: left;
  }
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  .error {
    color: red;
    font-size: 12px;
    margin-top: 10px;
  }
  button.navyBox {
    width: 100%;
    padding: 12px;
    background-color: #3c5997;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 20px;
    font-weight: bold;
  }
  button.navyBox:hover {
    background-color: #7388b6;
  }
  button.close {
    margin-top: 15px;
    background-color: #e2e2e2;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
  }
}
