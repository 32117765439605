.containerR {
  display: block !important;
  height: auto !important;
}

.ranking-container {
  width: 70% !important;
  padding: 100px 0 !important;
  margin: 0 auto !important;
  select {
    padding: 8px 10px !important;
    font-size: 14px !important;
    border-radius: 5px !important;
    border: 1px solid #ccc !important;
  }
  @media (max-width: 768px) {
    width: 85% !important;
    padding: 50px 0 !important;
  }
  .rankingInner {
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    @media (max-width: 1024px) {
      margin-top: 15px !important;
      gap: 10px !important;
    }
  }
}

.ranking {
  min-width: 160px !important;
  width: 30% !important;
  margin-bottom: 15px !important;
  @media (max-width: 768px) {
    width: 100% !important;
  }
  > div {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    h3 {
      color: #4c4c4c !important;
      font-size: 16px !important;
      margin-bottom: 10px !important;
    }
    .filters {
      width: 100% !important;
      justify-content: space-between !important;
      display: flex !important;
      margin: 0 0 10px 0 !important;
      gap: 10px !important;
      select {
        width: 40% !important;
        padding: 8px 10px !important;
        font-size: 14px !important;
        border-radius: 5px !important;
        border: 1px solid #ccc !important;
      }
    }
    @media (max-width: 768px) {
      .filters select {
        font-size: 11px !important;
        padding: 5px 6px !important;
      }
    }
  }
}

.profileWrap {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  padding: 10px !important;
  background-color: #f0f3f5 !important;
  border-radius: 5px !important;
  min-width: 120px !important;
  li.profile {
    display: flex !important;
    align-items: center !important;
    padding: 10px !important;
    background-color: white !important;
    border-radius: 5px !important;
    margin-bottom: 10px !important;
    @media (max-width: 768px) {
      padding: 5px 10px !important;
    }
    .profilePicture {
      width: 35px !important;
      height: 35px !important;
      border-radius: 50% !important;
      overflow: hidden !important;
      margin-right: 10px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      @media (max-width: 768px) {
        width: 30px !important;
        height: 30px !important;
      }
    }

    .profilePicture img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }

    .profileName {
      font-size: 14px !important;
      flex-grow: 1 !important;
    }

    .profileValue {
      font-size: 14px !important;
      color: #555 !important;
    }
  }
}

.userRankmodal {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.6) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1000 !important;
  animation: fadeIn 0.3s ease-in !important;

  .modal-content {
    background: #fff !important;
    padding: 20px !important;
    border-radius: 12px !important;
    width: 90% !important;
    max-width: 400px !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2) !important;
    position: relative !important;
    animation: slideIn 0.3s ease-out !important;
    border: 1px solid #ddd !important;
  }

  .close {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    cursor: pointer !important;
    font-size: 24px !important;
    color: #666 !important;
  }

  .profile-header {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 20px !important;
    gap: 15px !important;
    background: #f7f7f7 !important;
    padding: 15px !important;
    border-radius: 8px !important;
  }

  .profilePicture {
    display: flex !important;
    justify-content: center !important;
  }

  img {
    max-width: 80px !important;
    border-radius: 50% !important;
    border: 3px solid #eee !important;
  }

  h2 {
    font-size: 22px !important;
    color: #333 !important;
  }

  .user-details {
    display: flex !important;
    flex-direction: column !important;
  }

  .detail-item {
    display: flex !important;
    justify-content: space-between !important;
    padding: 12px 20px !important;
    border-bottom: 1px solid #eee !important;
    background: #f9f9f9 !important;
    border-radius: 4px !important;
  }

  .detail-item p {
    margin: 0 !important;
    font-size: 16px !important;
    color: #555 !important;
  }

  @keyframes fadeIn {
    from {
      opacity: 0 !important;
    }
    to {
      opacity: 1 !important;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(-20px) !important;
      opacity: 0 !important;
    }
    to {
      transform: translateY(0) !important;
      opacity: 1 !important;
    }
  }

  @media (max-width: 768px) {
    .modal-content {
      width: 95% !important;
      max-width: none !important;
    }

    .profile-header {
      flex-direction: column !important;
      align-items: center !important;
    }

    img {
      max-width: 60px !important;
    }

    h2 {
      font-size: 20px !important;
    }

    .detail-item {
      flex-direction: column !important;
      align-items: flex-start !important;
      padding: 10px 5px !important;
    }
  }
}
