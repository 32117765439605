.myCar {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0;
  }
}
.car-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  justify-content: center;
}
.car-image {
  width: 100%;
  max-width: 440px;
  height: auto;
  position: relative;
  cursor: pointer;
}
.car-image img {
  width: 100%;
  height: auto;
  overflow: auto;
}
.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 279px;
}
.upload-placeholder p {
  margin: 0;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    margin-bottom: 0;
    margin-left: -5px;
  }
}
.section-title {
  align-self: flex-start;
  margin-top: 20px;
  font-size: 16px;
  height: 25px;
}
.myCarInfo,
.insurance-info {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px 0;
  gap: 1.5%;
  @media (max-width: 768px) {
    gap: 2%;
  }
}
.myCarRow {
  display: flex;
  flex-direction: column;
  width: 23%;
  margin-bottom: 20px;
  margin-right: 10px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 32%;
    margin-right: 0;
    &.policyDuration {
      width: 95%;
    }
  }
}
.myCarLabel {
  font-weight: bold;
  margin-bottom: 5px;
  color: #c1c1c1;
  font-size: 14px;
  padding-left: 3px;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
}
.myCarValue {
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
}
.myCarValue input,
.myCarValue select {
  width: 50%;
  padding: 5px 5px 5px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: none;
  color: #c1c1c1;
  margin-right: 5px;
  @media (max-width: 768px) {
    margin: 0;
    width: 70%;
    font-size: 12px;
  }
}

.myCarValue input[type="date"] {
  width: calc(45% - 5px);
  @media (max-width: 768px) {
    margin: 0;
    width: 70%;
  }
}

.date-separator {
  margin: 0 18px 0 10px;
  color: #c1c1c1;
}

.myCarLog {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0;
  }
  .MyCarHeader {
    padding: 30px 20px;
    font-size: 14px;
    color: #4d4d4d;
    font-weight: 700;
    border-bottom: 1px solid #c1c1c1;
  }
  .displayRow {
    display: flex;
    flex-direction: row;
    font-size: 11px;
  }
  .MyCarHeader span {
    color: #05aced;
  }
  .records-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
  }
  .record-card {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;
  }
  .edited-notification {
    font-size: 14px;
    color: #ff0000;
    font-weight: bold;
    margin-left: 30px;
  }
  .record-detail {
    margin-bottom: 10px;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .pagination-button {
    padding: 10px 15px;
    margin: 0 5px;
    border: none;
    background-color: #05aced;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  .pagination-button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }
  .pagination-button:not(:disabled):hover {
    background-color: #0288c7;
  }
  .pagination-button.active {
    background-color: #0288c7;
    font-weight: bold;
  }

  .logMaintenance-item {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
  }
  .logMaintenance-item span {
    margin: 0 5px 0 0;
    padding: 6px;
    border-radius: 3px;
    background-color: #05aced;
    height: 25px;
    line-height: 25px;
    color: white;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
  }
  .penOppset {
    transform: scaleX(-1);
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .myCarMoney {
    font-size: 12px;
    font-weight: 700;
  }
  .date-and-distance {
    font-size: 11px;
    color: #4c4c4c;
  }
}

.MyCarMaintenance {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0 100px 0;
  }
}
.maintenance-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 20px;
  justify-content: space-between;
}
.no-items {
  width: 100%;
  text-align: center;
  color: #666;
}
.add-item {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 3.5%;
  width: 48%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 47%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
}
.add-item.centered {
  width: 100%;
  margin-top: 20px;
}
.add-icon {
  font-size: 24px;
  color: #0070f3;
}
