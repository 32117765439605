.board {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0;
  }
  .boardFlex {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px 0;
    justify-content: space-between;
    @media (max-width: 768px) {
      height: auto;
    }
  }
}

.boardBox {
  width: 47%;
  padding: 10px 20px;
  background: #fff;
  border: 10px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 100%;
  }
  .boardBoxnotice:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
  }
}
.boardBoxheader {
  font-size: 20px;
  font-weight: bold;
  margin: 5px 0;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}
.boardBoxnotice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  font-size: 15px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 5px 0;
    font-size: 14px;
  }
}
.boardBoxnotice:last-child {
  border-bottom: none;
}
.indexBox {
  background-color: #05aced;
  color: #fff;
  padding: 3px 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noticeTitle {
  flex: 1;
  margin-left: 10px;
}
.noticeDate {
  margin-left: 10px;
  white-space: nowrap;
}

.boardDetail {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0 200px;

  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0;
  }
  .burgerRelate {
    position: relative;
    .postControllButtonBox {
      position: absolute;
      right: 4px;
      border: 1px solid #d9d9d9;
      font-size: 14px;
      width: 116px;
      border-radius: 3px;
      background-color: #ffffff;
    }
    .adited {
      border-bottom: 1px solid #d9d9d9;
    }
    .postDelectButton {
      height: 35px;
      line-height: 35px;
      padding: 0 10px;
      cursor: pointer;
    }
  }
  .commentInputSet {
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 768px) {
      padding: 0;
      width: 100%;
    }
    .commentInput {
      width: 95%;
      border-radius: 3px;
      height: 59px;
      border: 1px solid #d9d9d9;
      padding: 10px 0 0 10px;
      font-size: 14px;
      outline: none;
      resize: none; /* 사용자 크기 조정 비활성화 */
      @media (max-width: 768px) {
        height: 50px;
        font-size: 12px;
      }
    }
    .commentPost {
      width: 60px;
      margin-left: 10px;
      border-radius: 3px;
      background-color: black;
      color: white;
    }
  }

  .detailFooterSet {
    padding: 80px 20px 20px;
    @media (max-width: 768px) {
      padding: 30px 0;
    }
  }
  .commentSetting {
    padding: 15px 0px;
    border-bottom: 1px solid #d9d9d9;
  }
  .detailUser {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .detailUserdetail {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .detailUserdetailAdd {
    width: 40%;
    @media (max-width: 768px) {
      width: 60%;
    }
  }
  .detailName {
    font-size: 14px;
    margin: 0 7px;
    @media (max-width: 768px) {
      margin: 0px;
    }
  }
  .commentDetail {
    font-size: 13px;
    margin: 0 7px;
  }
  .detailUserLast {
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      gap: 5px;
    }
    p {
      margin: 5px 7px;
      color: #999;
      font-size: 11px;
      @media (max-width: 768px) {
        margin: 0;
      }
    }
  }
  .dlatldlatl {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }
  .dlatl {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  section {
    border: 1px solid #999;
    border-radius: 10px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    h2 {
      font-size: 32px;
      font-weight: 400;
      margin: 8px 0px 24px;
      @media (max-width: 768px) {
        font-size: 18px;
        margin: 8px 0px 18px;
      }
      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
    .detailHeaderSet {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #d9d9d9;
      @media (max-width: 768px) {
        padding-bottom: 10px;
      }
    }
    .detailBodyDetail {
      min-height: 500px;
      padding: 20px;
      img {
        max-width: 100%;
      }
      .ql-size-huge {
        font-size: 32px;
      }
      @media (max-width: 768px) {
        padding: 10px 5px;
        font-size: 13px;
      }
    }
    .detailFooterStart {
      display: flex;
      flex-direction: row;
      padding-bottom: 30px;
      border-bottom: 1px solid #d9d9d9;
      @media (max-width: 768px) {
        font-size: 12px;
      }
      div {
        margin: 0px 5px;
      }
    }
    .DetailTopFlex {
      width: 100%;
    }
  }
  .boardPostHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 10px 0px;
  }
  .writeButton {
    padding: 8px 16px;
    background-color: #05aced;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin: 0 0 0 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
}

.boardPost {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0;
  }
}
.boardPostHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .writeButton {
    padding: 8px 16px;
    background-color: #05aced;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    &:hover {
      background-color: #05aced;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.searchWrapper {
  display: flex;
  margin: 50px auto;
  align-items: center;
  justify-content: center;

  .searchSelect {
    margin-right: 10px;
    padding: 8px 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .searchInput {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
    width: 200px;
    @media (max-width: 768px) {
      width: 55%;
    }
  }
  .searchButton {
    padding: 8px 16px;
    background-color: #05aced;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    &:hover {
      background-color: #05aced;
    }
    @media (max-width: 768px) {
      font-size: 12px;
      padding: 10px;
    }
  }
}

.tableWrapper {
  margin-top: 20px;
  height: 503px;
  overflow-y: hidden; /* 세로 스크롤 제거 */
  overflow-x: hidden;
  @media (max-width: 768px) {
    height: auto;
  }
}
.postTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  th {
    background-color: #fff;
    font-weight: bold;
    border-top: 1px solid black;
    border-bottom: 1px solid #ddd;
    padding: 1.5% 0;
    &:nth-of-type(1) {
      width: 10%;
    }
    &:nth-of-type(2) {
      width: 50%;
    }
    &:nth-of-type(3) {
      width: 15%;
    }
    &:nth-of-type(4) {
      width: 15%;
    }
    @media (max-width: 768px) {
      font-size: 13px;
      &:nth-of-type(1) {
        width: 10%;
      }
      &:nth-of-type(2) {
        width: 50%;
      }
      &:nth-of-type(3) {
        width: 20%;
      }
      &:nth-of-type(4) {
        width: 20%;
      }
    }
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
  td {
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 1%;
    &:nth-of-type(2) {
      text-align: left;
    }
    @media (max-width: 768px) {
      font-size: 13px;
    }
    @media (max-width: 480px) {
      font-size: 11px;
      &:nth-of-type(3) {
        font-size: 10px;
      }
      &:nth-of-type(4) {
        font-size: 10px;
      }
    }
  }
}

.postItem:hover {
  cursor: pointer;
}
.postItemDate {
  color: #999;
}
.pagination {
  margin-top: 50px;
  text-align: center;
}
.pageButton {
  background-color: #05aced;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  margin-right: 5px;
  outline: none;
  &:hover {
    background-color: #05aced;
  }
  &.active {
    background-color: #05aced;
    font-weight: bold;
  }
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
}

.BoardPostAdd {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0 200px;
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0;
  }
}

.boardPostHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 10px 0px;
  .writeButton {
    padding: 8px 16px;
    background-color: #05aced;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin: 0 0 0 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
}
.BoardPostSection {
  border: 1px solid #999;
  border-radius: 10px;
  padding: 30px 30px 100px;
  @media (max-width: 768px) {
    padding: 20px;
  }
}

.BoardPostHeader {
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 10px;
  }
  .PostTitle {
    height: 70px;
    font-size: 30px;
    border: none;
    padding: 0 0 0 10px;
    flex-grow: 1;
    @media (max-width: 768px) {
      font-size: 20px;
      padding: 0;
      height: auto;
    }
  }
  .boardSelect {
    margin-left: 20px;
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    @media (max-width: 768px) {
      margin-left: 0px;
      font-size: 14px;
    }
  }
}
.EditorWrapper {
  margin-top: 20px;
  height: 700px; /* 높이 600픽셀로 설정 */
  @media (max-width: 768px) {
    height: 500px;
  }
}
.EditorWrapper .ql-container {
  height: 100%; /* 부모 높이에 맞게 설정 */
  @media (max-width: 768px) {
    height: calc(100% - 120px);
  }
}

.boardComment {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 768px) {
    width: 85%;
    padding: 50px 0;
  }

  .boardCommentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .tableWrapper {
    height: 300px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  .commentTable {
    width: 100%;
    border-collapse: collapse;
  }

  .commentTable th,
  .commentTable td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }

  .commentTable th {
    background-color: #f7f7f7;
  }

  .commentTable tr:hover {
    background-color: #f1f1f1;
  }
}
