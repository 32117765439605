.signup-container {
  background-color: rgb(244, 244, 244);
  .signup-box {
    max-width: 350px;
    width: 70%;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0px;
      box-shadow: 0 0 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  h2 {
    img {
      width: 35px;
      height: 35px;
    }
    margin-bottom: 20px;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }
  h3 {
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  button.navyBox {
    width: 100%;
    padding: 12px;
    background-color: #3c5997;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
      width: 70%;
      font-size: 16px;
    }
    a {
      color: white;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #7388b6;
    }
  }
  .smallText {
    font-size: 12px;
    text-align: right;
    padding: 10px 0;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    a {
      color: rgb(132 141 148);
      font-weight: bold;
    }
  }
}

.signup-containerE {
  padding: 80px 0 150px 0;
  height: auto !important;

  ul,
  li,
  ol {
    list-style: none;
  }
  .signup-box {
    max-width: 350px;
    width: 70%;
    margin: 0 auto;
    background-color: white;

    @media (max-width: 768px) {
      width: 85%;
      height: calc(100vh - 80px);
    }
  }
  button.goBack {
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    width: 45px;
    height: 45px;
    text-align: left;
    position: relative;
    img {
      width: 30%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  h3 {
    font-size: 24px;
    margin: 50px 0;
    @media (max-width: 768px) {
      font-size: 20px;
      margin: 30px 0;
    }
  }
  p {
    font-size: 14px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 12px;
      white-space: nowrap;
      margin: 15px 0;
    }
  }
  .input-container {
    margin-bottom: 15px;
    text-align: left;

    label {
      display: block;
      color: rgb(156, 165, 173);
      font-size: 12px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    input {
      width: 95%;
      padding: 10px 0 10px 5px;
      border: none;
      border-bottom: 1px solid #d0d7de;
      font-size: 16px;
      color: rgb(156, 165, 173);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    input:focus {
      border-color: #222;
      outline: none;
      color: #222;
    }
    ul {
      margin-left: 15px;
      margin-top: 5px;
      margin-bottom: 20px;
      li {
        list-style: disc;
        color: #555;
        font-size: 14px;
        line-height: 20px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
  .agreePrivacy,
  .agreeTerms {
    display: flex;
    flex-direction: row;
  }
  .terms {
    margin-top: 100px;
    @media (max-width: 768px) {
      margin-top: 70px;
    }
    p:not(:nth-of-type(1)) {
      font-size: 14px;
      padding: 0;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
    .agreeAll {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #d9d9d9;
      padding: 20px 0;
      @media (max-width: 768px) {
        font-size: 14px;
        padding: 10px 0;
      }
    }
    label {
      margin-left: 10px;
    }
    input {
      cursor: pointer;
    }
  }
  button.navyBox {
    width: 100%;
    padding: 12px;
    background-color: #3c5997;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    a {
      color: white;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #7388b6;
    }
  }
  .smallText {
    font-size: 12px;
    text-align: right;
    padding: 10px 0;
    a {
      color: rgb(132 141 148);
      font-weight: bold;
    }
  }
}

.signup-containerJ {
  padding: 30px 0 120px 0;
  height: auto !important;

  .signup-box {
    max-width: 350px;
    width: 70%;
    margin: 0 auto;
    background-color: white;
    height: calc(100vh - 80px);
    @media (max-width: 768px) {
      width: 85%;
      height: calc(100vh - 80px);
    }
  }
  button.goBack {
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    width: 45px;
    height: 45px;
    text-align: left;
    position: relative;
    img {
      width: 30%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  h3 {
    font-size: 24px;
    margin: 50px 0;
    @media (max-width: 768px) {
      font-size: 20px;
      margin: 30px 0;
    }
  }
  p {
    font-size: 14px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 14px;
      margin: 15px 0;
    }
  }
  .jobBox {
    margin-bottom: 50px;
    button {
      margin-right: 10px;
      padding: 10px 20px;
      background-color: #ddd;
      border-radius: 5px;
      cursor: pointer;
      @media (max-width: 768px) {
        font-size: 14px;
      }
      &.selected {
        background-color: #05aced;
        color: white;
      }
    }
  }
  button.navyBox {
    width: 100%;
    padding: 12px;
    background-color: #3c5997;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    a {
      color: white;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #7388b6;
    }
  }
}

.signup-containerP {
  padding: 80px 0 150px 0;
  height: auto !important;
  ul,
  li,
  ol {
    list-style: none;
  }
  .signup-box {
    max-width: 350px;
    width: 70%;
    margin: auto;
    background-color: white;

    @media (max-width: 768px) {
      width: 85%;
      height: calc(100vh - 80px);
    }
  }
  button.goBack {
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    width: 45px;
    height: 45px;
    text-align: left;
    position: relative;
    img {
      width: 30%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  h3 {
    font-size: 24px;
    margin: 50px 0;
    @media (max-width: 768px) {
      font-size: 20px;
      margin: 30px 0;
    }
  }
  .input-container {
    margin-bottom: 15px;
    text-align: left;
    &.security {
      margin-top: 15%;
    }
    ul {
      margin-left: 15px;
      margin-top: 5px;
      margin-bottom: 20px;
      li {
        list-style: disc;
        color: #555;
        font-size: 14px;
        line-height: 20px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
    label {
      display: block;
      color: rgb(156, 165, 173);
      font-size: 12px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    input {
      width: 95%;
      padding: 10px 0 10px 5px;
      border: none;
      border-bottom: 1px solid #d0d7de;
      font-size: 16px;
      color: rgb(156, 165, 173);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    input:focus {
      border-color: #222;
      outline: none;
      color: #222;
    }
    select {
      width: 95%;
      margin-top: 10px;
      padding: 10px 0;
      border: none;
      border-bottom: 1px solid #d0d7de;
      font-size: 16px;
      color: rgb(156, 165, 173);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    select:focus {
      border-color: #222;
      outline: none;
      color: #222;
    }
  }
  .error {
    margin-top: 5px;
    font-size: 11px;
    color: rgb(255, 80, 0);
  }
  button.navyBox {
    width: 100%;
    padding: 12px;
    background-color: #3c5997;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    a {
      color: white;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #7388b6;
    }
  }
}
